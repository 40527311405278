@import 'src/assets/styles/base';

.app {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;

  &__header,
  &__footer,
  &__main {
    flex-shrink: 0;
  }

  &__main {
    flex-grow: 1;
  }
}
