// Breakpoints
$breakpoints: (
  sm: 768px,
  md: 1024px,
  lg: 1200px,
  xl: 1440px,
);

// FONTS
$font-size-base: 16px;
$font-family-base: Arial;

// SIZES

// COLORS
